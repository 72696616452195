<template>

 <div class="limiter">
		<div class="container-login100">
      
			<div class="wrap-login100">
        
        <h2 style="margin-top:-140px; margin-left:100px; color:rgb(237,28,36); font-family:Arial, Helvetica, sans-serif; font-weight:bold">ASANTEMAN GREAT GOSPEL CRUSADE</h2>
         
				<Tilt :parallax="true">
					<img src="@/assets/CfaN_Plain_Logo_red.png" style="width:300px; margin-top:60px" alt="IMG">
				</Tilt>

        <transition name="slide-fade">
        <div v-if="show_login">
				<form class="login100-form validate-form" @submit.prevent="login()">
					<span class="login100-form-title">
						AGGC - Login
					</span>

    

          <div><label><b>Enter Your Email As Username</b></label></div>
					<div class="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
            
						<input class="input100" autocomplete="true"  :class="{ form_error: emailErr}" name="email" @blur="emailVal($event.target.value)" v-model.trim="formData.email"  type="text"  placeholder="Enter Email As Username">
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<i class="fa fa-envelope" aria-hidden="true"></i> 
						</span>
             <div
                    :class="{ text_error: emailErr  }"
                  v-if="emailErr">
                  {{ emailErr }} 
                  </div>
                
					</div>


          <div><label><b>Password</b></label></div>
					<div class="wrap-input100 validate-input" data-validate = "Password is required">
						<input class="input100"  :class="{ form_error: passwordErr}" @blur="passwordVal($event.target.value)" v-model.trim="formData.password" type="password"  placeholder="Enter Your Password">
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<i class="fa fa-lock" aria-hidden="true"></i>
						</span>
              <div
                    :class="{ text_error: passwordErr  }"
                  v-if="passwordErr">
                  {{ passwordErr }} 
                  </div>
					</div>
					
					<div class="container-login100-form-btn">
						<button type="submit" class="login100-form-btn">
							Login
						</button>
					</div>

					<div class="text-center p-t-12">
						<!-- <span class="txt1">
							Forgot
						</span>
           <a href="#" @click.prevent="show_forgot_item" class="txt2">
               Forgot Password
           </a> -->
					</div>

					<!-- <div class="text-center p-t-136">
						<a class="txt2" href="#">
							Create your Account
							<i class="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
						</a>
					</div> -->
				</form>
        </div>
        </transition>


       <transition name="slide-fadee">
         <div v-if="show_forgot">
				<form class="login100-form validate-form" @submit.prevent="forgot_password()">
					<span class="login100-form-title">
						Forgot Password
					</span>

    

          <div><label><b>Enter Your Email To Receive Link To Reset Your Password</b></label></div>
					<div class="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
            
						<input class="input100" autocomplete="true"  :class="{ form_error: emailErr}" name="email" @blur="emailVal($event.target.value)" v-model.trim="formData.email"  type="text"  placeholder="Enter Email As Username">
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<i class="fa fa-envelope" aria-hidden="true"></i> 
						</span>
             <div
                    :class="{ text_error: emailErr  }"
                  v-if="emailErr">
                  {{ emailErr }} 
                  </div>
                
					</div>


       
					
					<div class="container-login100-form-btn">
						<button type="submit" class="login100-form-btn">
							Send
						</button>
					</div>

					<div class="text-center p-t-12">
						<span class="txt1">
							Forgot
						</span>

             <a href="#" @click.prevent="show_login_item" class="txt2" v-if="show_forgot">
                Return To Login
           </a>
					</div>

					<!-- <div class="text-center p-t-136">
						<a class="txt2" href="#">
							Create your Account
							<i class="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
						</a>
					</div> -->
				</form>
        </div>
       </transition>

   


			</div>
		</div>
	</div>
	
</template>

<script>
import axios from 'axios';
import FieldValidateService from "@/services/FieldValidateService";
import { mapActions, mapMutations, mapState } from 'vuex';
import notificationBox from '@/services/notificationBox';
import { AUTH_LOGIN, LOADING } from '@/store/storeconstants';
//import jwt_decode from "jwt-decode";
//import vSelect from 'vue-select'
import Tilt from 'vanilla-tilt-vue';


  



export default {    
  name: "LoginView",
  components: {
    Tilt
  //  vSelect,
  },
  async created(){


            
  },


  data: function(){
      return {
          errorClient:[],
          errorServers:"",
          page:"",
          route_name:"",
          showPassword:false,
          show_login:true,
          show_forgot:false,

          formData:{
            email:"",
            password:"",
          },

          emailErr:"",
          passwordErr:"",

          
      }
  },



  computed:{
    ...mapState('auth',['verified','verified_portal','verified_username'])
  },


  methods:{

    ...mapActions({
          loginAct: 'auth/'+AUTH_LOGIN,
    }),

    
     
    ...mapMutations({
       showLoading:LOADING,
   }),


   show_forgot_item(){

    this.show_login = false;
    
   setTimeout(()=>{
  this.show_forgot = true;
},800)
   },



   show_login_item(){
    setTimeout(()=>{
    this.show_login = true;
    },800)
    this.show_forgot = false;

   },


    async login(){

       this.showLoading(true);

           this.errorClient = [];

           this.emailVal(this.formData.email);
           this.passwordVal(this.formData.password);

           if(this.errorClient.length > 0){
            notificationBox.errorNotifications("Some Errors Exist");
           }

           else
           {

      try{



        let response = await axios.post("login",this.formData)

        // console.log(response);

       // return response;

        if(response.status == 200){

          this.showLoading(false);


          if(response.data.error){

            notificationBox.errorNotifications(response.data.error);


          } else {
          //  this.showLoading(false);

            localStorage.setItem("token",response.data.token);
           // let port_num = response.data.port_num;
            notificationBox.succNotifications("Login Successful");
          //  let token = response.data.token;
            let port_num = response.data.port_num;
            //console.log(token);
            if(port_num <= 1 )
            {
            localStorage.setItem("user_portal_info",JSON.stringify(response.data.current_portal))
           // localStorage.setItem("user_info",response.data.user_info)
           // let current_portall =  localStorage.getItem("user_portal_info");
             //console.log(current_portall);
            //this.$router.push("/denomination");
            if(response.data.user_level != "FIRECONFERENCE")
            {
            window.location.href = '/convert';
            }
            else{
              window.location.href = '/fire_conference_report';
            }
            }
            else{
              localStorage.setItem("portals",JSON.stringify(response.data.current_portal));
            //this.$router.push("/main-menu");
              window.location.href = '/main-menu';
            }

        }
      }

      }catch(error){

         this.showLoading(false);
        console.log(error);
        notificationBox.errorNotifications(error.response.data.error);

      }

           }

    


        
  },








         async forgot_password(){

           this.showLoading(true);

           this.errorClient = [];

           this.emailVal(this.formData.email);

           if(this.errorClient.length > 0){
            notificationBox.errorNotifications("Some Errors Exist");
           }

           else
           {

      try{



        let response = await axios.post("forgot",this.formData)

        if(response.status == 200){

           this.showLoading(false);

           this.clearFormData();
            notificationBox.succNotifications("Link To Reset Password Sent To Your Email");
         

        }

      }catch(error){

         this.showLoading(false);
        console.log(error);
        notificationBox.errorNotifications(error.response.data.data_error);

      }
           }

         },









           clearFormData(){


           this.errorServers = "";
        this.errorClient = [];
      //this.clear_user = false;
      this.formData.email = "";
      this.formData.password = "";
      this.emailErr = "";
      this.passwordErr = "";
     
           },



        emailVal(value) {

  
      let field = "Email";
     
     if(FieldValidateService.reqVal(value,field))
     {
       this.emailErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.emailErr);
     }else{

           if(this.errorClient.includes(this.emailErr))
           {
            let pos = this.errorClient.indexOf(this.emailErr);
           this.errorClient.splice(pos,1);
            this.emailErr =""; 
           }
          
          

     }
    },






          passwordVal(value) {

  
      let field = "Password";
     
     if(FieldValidateService.reqVal(value,field))
     {
       this.passwordErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.passwordErr);
     }else{

           if(this.errorClient.includes(this.passwordErr))
           {
            let pos = this.errorClient.indexOf(this.passwordErr);
           this.errorClient.splice(pos,1);
            this.passwordErr =""; 
           }
          
          

     }
    },



  }
}
</script>

<style scoped>
/**
   *  Bootstrap  CSS
   */
  @import "../assets/login/vendor/bootstrap/css/bootstrap.min.css";
   /**
   *  Font Awesome  CSS
   */
  @import "../assets/login/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
   /**
   *  Animate  CSS
   */
  @import "../assets/login/vendor/animate/animate.css";
   /**
   *  CSS AMBURGERS
   */
  @import "../assets/login/vendor/css-hamburgers/hamburgers.min.css";
   /**
   *  SELECT2  CSS
   */
  @import "../assets/login/vendor/select2/select2.min.css";
   /**
   *  UTIL  CSS
   */
  @import "../assets/login/css/util.css";
   /**
   *  MAIN  CSS
   */
  @import "../assets/login/css/main.css";
  @import "../assets/login/css/main.css";


  .slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}





  .slide-fadee-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fadee-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fadee-enter-from,
.slide-fadee-leave-to {
  transform: translateX(20px);
  opacity: 0;
}




</style>