<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
        <div class="main">
            <div class="section__content section__content--p30">
                <div class="container-fluid">  
                    <HeaderComp page="Fire Conference Report" pageDesc="report"/>                
                    <section id="main-content">
                        <div class="row ps-3 pe-3">
                            <div class="col-md-12">                               
                                <div class="row">

                                   
                                    <!-- <PerPageComponent @perpageValue="perpageval" />

                                    <TableFilter @statusValue="statusval" portall="" />

                    

                                    <TableSearch @searchValue="searchval" />


                                  
    


                                    <ActionButton  @deleteRecords="deleteRecords" :num_checked="checked.length" portall="" />









                                
                                    <div class="col-2 text-end">
                        

                                                   
                                        <a class="btn btn-outline-success" @click.prevent="crusade_typeAdd" ref="crusade_type_add_ref" data-bs-toggle="modal"  href="#crusade_typeModal" v-if="app_portal=='MAIN'">
                                            <i class="fas fa-plus"></i> Add Crusade Type
                                        </a>   


                                           <a style="display:none" class="btn btn-outline-success" ref="crusade_type_ref" data-bs-toggle="modal"  href="#crusade_typeModal">
                                            <i class="fas fa-plus"></i> Crusade Type Ref
                                        </a>   

                              

                                        
                                    </div>


                            


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" /> -->


                                </div>
                            </div>


                            <div class="card shadow-sm" id="stylized">
                              <form class="register-form" @submit.prevent="searchConvert()">

                                <div class="card-body">



                             <div class="row">

                               <div class="col">
                              <label>Search Type</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: search_typeErr}"
                             @change="search_typeVal($event.target.value)"
                            v-model.trim="formData.search_type">
                            <!-- <option value="">--Choose An Option--</option> -->
                            <option value="STATIC" selected>STATIC</option>
                            <!-- <option value="DYNAMIC">DYNAMIC</option> -->
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: search_typeErr}"
                            v-if="search_typeErr"
                          >
                            {{ search_typeErr }}
                          </div>
                      </div>

                      


                          <div class="col">
                                <label><span class="text_error">*</span>Search By</label>
                             <div class="input-group" style="width:100%">
                          

                         <v-select :options="getSearchBy"   class="form-control select1" v-model="formData.search_by"  :reduce="search_by => search_by.search_by" label="search_by_act_name" @option:selected="search_byVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: search_byErr }"
                            v-if="search_byErr"
                          >
                            {{ search_byErr }}
                          </div>

                      </div>







                   <div class="col" v-if="formData.search_type=='DYNAMIC'">
                                <label><span class="text_error">*</span>Select Column To View</label>
                             <div class="input-group" style="width:100%">
                          

                         <v-select :options="getColumns" multiple  class="form-control select1" v-model="formData.column"  :reduce="column => column" label="column_view" @option:selected="columnVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: columnErr }"
                            v-if="columnErr"
                          >
                            {{ columnErr }}
                          </div>

                      </div>

         



                     




                    </div>

                          <div class="row">



                    <div class="col" v-if="formData.search_by != 'ALL'">
                        <label><span class="text_error">*</span>Search Value</label>
                        <div class="input-group">
                          

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: search_valueErr}"
                            placeholder="Search Value"
                            @blur="search_valueVal($event.target.value)" 
                            v-model.trim="formData.search_value"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: search_valueErr  }"
                           v-if="search_valueErr">
                          {{ search_valueErr }} 
                          </div>
                      </div>





                            <div class="col">
                              <label>View Type</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: view_typeErr}"
                             @change="view_typeVal($event.target.value)"
                            v-model.trim="formData.view_type">
                            <option value="GRID" selected>GRID</option>
                            <!-- <option value="REPORT">REPORT</option> -->
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: view_typeErr}"
                            v-if="view_typeErr"
                          >
                            {{ view_typeErr }}
                          </div><br/>

                            <button type="submit" class="btn btn-primary">Generate Report</button>

                      </div>


              


                          



                          </div>

                         
                                   
                                </div>
                                 </form>


                            <!-- Table Goes Here -->

                            </div>

                              <div class="col-md-12" v-if="formData.view_type=='GRID'"> 
                              <div class="row">

                                   
                                    <PerPageComponent @perpageValue="perpageval" />

                                    <!-- <TableFilter @statusValue="statusval" portall="" /> -->

                    

                                    <TableSearch @searchValue="searchval" />


                                  
    


                                    <ActionButton  @deleteRecords="deleteRecords" :num_checked="checked.length" portall="" />









                                
                                    <div class="col-3 text-end">
                        

                                        <div v-if="grid_converts.length">Search Result/Total:{{ total }} / {{ this.totalPastors }}</div>

                                       
                                        <div v-if="getsearch">Filter Text : {{ getsearch }} </div>
                                        <!-- <a class="btn btn-outline-success" @click.prevent="crusade_typeAdd" ref="crusade_type_add_ref" data-bs-toggle="modal"  href="#crusade_typeModal" v-if="app_portal=='MAIN'">
                                            <i class="fas fa-plus"></i> Add Crusade Type
                                        </a>   


                                           <a style="display:none" class="btn btn-outline-success" ref="crusade_type_ref" data-bs-toggle="modal"  href="#crusade_typeModal">
                                            <i class="fas fa-plus"></i> Crusade Type Ref
                                        </a>    -->

                              

                                        
                                    </div>


                            


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" />


                                </div>
                        </div>



                         <div class="card shadow-sm">
                                <div class="card-body" v-if="formData.view_type=='GRID'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped" v-if="formData.search_type == 'STATIC'">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('pastors.fulname')">Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='pastors.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='pastors.fullname'">&darr;</span>
                                                </th>


                                              <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('pastors.gender')">Gender</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='pastors.gender'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='pastors.gender'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('pastors.contact')">Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='pastors.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='pastors.contact'">&darr;</span>
                                                </th>


                                                <!-- <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('pastors.email')">Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='pastors.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='pastors.email'">&darr;</span>
                                                </th> -->

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('pastors.residential_address')">Residential Address</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='pastors.residential_address'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='pastors.residential_address'">&darr;</span>
                                                </th>


                                                
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('pastors.organisation')">Organisation</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='pastors.organisation'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='pastors.organisation'">&darr;</span>
                                                </th>


                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('pastors.church_location')">Church Location</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='pastors.church_location'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='pastors.church_location'">&darr;</span>
                                                </th>



                                             <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('pastors.pastor_name')">Pastor's Name</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='pastors.pastor_name'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='pastors.pastor_name'">&darr;</span>
                                                </th>


                                             <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('pastors.position_in_church')">Position In Church</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='pastors.position_in_church'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='pastors.position_in_church'">&darr;</span>
                                                </th>



                                                   <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('pastors.date')">Date</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='pastors.date'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='pastors.date'">&darr;</span>
                                                </th>



                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.gender }}</td>
                                                    <td>{{cov.contact }}</td>
                                                    <!-- <td>{{cov.email }}</td> -->
                                                    <td>{{cov.residential_address }}</td>
                                                    <td>{{cov.organisation }}</td>
                                                    <td>{{cov.church_location }}</td>
                                                    <td>{{cov.pastor_name }}</td>
                                                    <td>{{cov.position_in_church }}</td>
                                                    <td>{{cov.date }}</td>
                                                    <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>








                                           <table class="table table-hover table-striped" v-if="formData.search_type == 'DYNAMIC'">
                                            <thead>
                                              
                                                <tr>
                                               
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col"><input type="checkbox" v-model="selectPage" /></th>

                                                <th scope="col"  v-for="(col_item,index) in formData.column" :key="index" >
                                                <a href="#" @click.prevent="change_sort('col_item.column_field')">ME</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='col_item.column_field'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='col_item.column_field'">&darr;</span>
                                                </th>


                                              <!-- <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('denominations.denomination')">Denomination</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='denominations.denomination'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='denominations.denomination'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('churches.church')">Church</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='churches.church'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='churches.church'">&darr;</span>
                                                </th>


                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('converts.gender')">Gender</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='converts.gender'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='converts.gender'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('converts.residence_phone_number')">Residence / Personal Phone Number</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='converts.residence_phone_number'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='converts.residence_phone_number'">&darr;</span>
                                                </th>


                                                
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('converts.first_time_decision_for_christ')">First Time Decision For Christ</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='converts.first_time_decision_for_christ'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='converts.first_time_decision_for_christ'">&darr;</span>
                                                </th> -->


                                     

                                               



                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    
                                                    <td v-for="(col_itemm,index) in formData.column" :key="index">
                                                      {{cov.col_itemm.column}}
                                                    </td>
                                                    <!-- <td>{{cov.denomination }}</td>
                                                    <td>{{cov.church }}</td>
                                                    <td>{{cov.gender }}</td>
                                                    <td>{{cov.residence_phone_number }}</td>
                                                    <td>{{cov.first_time_decision_for_christ }}</td> -->
                                                    <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                       
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>



                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>
                         </div>



                         




                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
        </div> 

  


        <!-- <UserModals @userAdded="userAdded"  @provider_view_typee="provider_view_typee"   @country_view_typee="country_view_typee"  :getUserPortalData="getUserPortalData"   :view_type="view_type"  />

        <EmployeeUserCountry @userView="userView" :employee_country_view_type="country_view_type" :employee_user_id="employee_user_id"  />    
  
        <EmployeeUserProvider @userView="userView"   :employee_provider_view_type="provider_view_type" :employee_provider_user_id="employee_provider_user_id" />      -->
        <!-- Modal -->
        <!-- <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> -->
    </div>
</template>


<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"
import PerPageComponent from "@/components/table/PerPage.vue"
import TableSearch from "@/components/table/TableSearch"
// import TableFilter from "@/components/table/TableFilter"
import ActionButton from "@/components/table/ActionButton"
import SelectAllComponent from "@/components/table/SelectAllComponent"
import axios from "axios"
import FieldValidateService from "@/services/FieldValidateService";

//import "@ocrv/vue-tailwind-pagination/dist/style.css";
import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
import "@ocrv/vue-tailwind-pagination/styles";
import notificationBox from '@/services/notificationBox'
import {BASEURL} from '@/services/UserService';
import {  mapMutations, mapState } from 'vuex';
import { LOADING, TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from '@/store/storeconstants';
import vSelect from 'vue-select';


export default {
  name: "ConvertReportView",
    beforeRouteEnter(to,from,next){
     let user_level = "";
     if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
     const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
     user_level = user_data[0].user_level;
     }
     else{
             const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
             user_level = user_data.user_level;
     }
   
    
    if(user_level=='SUPER ADMIN' || user_level=='FIRECONFERENCE'){
        next();

    }else{
  
        next("/user-not-authorise"); 
    }
 
    
  },
  components: {
    HeaderComp, 
    FooterComp,
    SideBarComp, 
    NavBarComp,
    PerPageComponent,
    TableSearch,
    // TableFilter,
    ActionButton,
    SelectAllComponent,
    vSelect,
    VueTailwindPagination,

  },



  data(){
    return {

      grid_converts:[],
      report_converts:{},
        //showUserModals:false,

        //currentPage:1,
        //total:100,
        perPage:20,
        //perPage:20,
        search:"",
        totalPastors:"",
        is_active: "",
        checked:[],
        user_table_data:{},
        user_portal_table_data:{},
        getCrusadeTypeData:{},
        view_type:"",
        app_portal:"",
        user_portal_info:{},
        getSearchBy:[],
        getColumns:[],
        errorClient:[],
        errorServers:[],
        // provider_id:"",
        // country_view_type:"",
        // provider_view_type:"", 
        // employee_user_id:"",
        // employee_provider_user_id:"",
        // support_id:"",
        // dependent_id:"",
        // getSupportData:{},


        login_user_id:"",
        user_level:"",
        selectPage:false,

         formData:{
            search_type:"STATIC",
            search_by:"",
            column:[],
            search_value:"",
            view_type:"GRID",

         },

         search_typeErr:"",
         search_byErr:"",
         columnErr:"",
         search_valueErr:"",
         view_typeErr:"",


    }
  },


  computed:{
    ...mapState('dataTable',['currentPage','url','total','perpage','getstatus','getsearch','selectAll','sort_direction','sort_field']),
    ...mapState("user_portal",['search_user_portal_id_result']),
    //...mapState("user",['search_user_id_result'])
  },



  methods:{

       ...mapMutations({
       table_select_all: 'dataTable/'+TABLE_SEL_ALL,
       table_sort_field_direction: 'dataTable/'+TABLE_SORT_FIELD_DIRECTION,
       table_current_page:'dataTable/'+TABLE_CURRENT_PAGE,
       table_url:'dataTable/'+TABLE_URL,
       table_per_page :'dataTable/'+TABLE_PER_PAGE,
       table_total :'dataTable/'+TABLE_TOTAL,
       table_search :'dataTable/'+TABLE_SEARCH,
       table_status :'dataTable/'+TABLE_STATUS,
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),




    selectAllRecords(){

        axios.get('crusade_types/all?is_active=' + this.getstatus)
             .then(response => {
                 console.log(response.data);
                  this.checked = response.data;
                  // this.selectAll = true;
                 // this.table_checked(response.data);
                   this.table_select_all(true);
             });
    },



    change_sort(field){
        // if(this.sort_field == field){
        //  this.sort_direction =   this.sort_direction == "asc" ? "desc" : "asc"
        // }else{
        //     this.sort_field = field;
        // }
        this.table_sort_field_direction(field);
        this.searchConvert();

    },



   deleteSingleRecord(id){

    //console.log(id);

       axios.delete(`crusade_types/${id}`)
       .then( (response) => {
        this.checked = this.checked.filter(crusade_type_id => crusade_type_id != id)
        //this.table_checked(this.checked.filter(user_portal_id => user_portal_id != id))
        console.log(response.data.success)
        notificationBox.succNotifications("Deleted Successfully");
         this.getCrusadeType();
       }).catch(error => {
        console.log(error.message)
         notificationBox.errorNotifications(error)
       })


   },



   deleteRecords(){
      axios.delete(`crusade_types/massDestroy/${this.checked}`)
      .then((response) => {
         if(response.status == 204){
            console.log(response.data.success)
            notificationBox.succNotifications("Selected Crusade Types were Deleted Successfully");
            this.checked = [];
            //this.table_checked([])
            this.searchConvert();

         }
      })
   },





   


    pageChange(pageNumber){

        //this.currentPage = pageNumber;
        this.table_current_page(pageNumber);
        this.searchConvert();

    },




   isChecked(id){

    return  this.checked.includes(id);

   },



    getCrusadeType(){
   
        axios.get(
            'crusade_types?page=' + this.currentPage + 
            '&page_size=' + this.perpage + 
            '&q=' +this.getsearch + 
            '&is_active=' + this.getstatus +
            '&sort_direction=' + this.sort_direction +
            '&sort_field=' + this.sort_field +
            '&portal=' + this.app_portal +
            '&crusade_type_id=' + this.crusade_type_id 
            )
        .then(response => {

            this.crusade_types = response.data.data.data;
            //this.perPage = response.data.data.per_page;
               this.table_per_page(response.data.data.per_page);
            //this.total = response.data.data.total;
             this.table_total(response.data.data.total)
        });

    },



    perpageval(value){
        this.table_per_page(value);
       this.searchConvert();

    },


    // banSucc(){

    //     this.getSupportMember();

    // },


    searchval(value){

         this.table_search(value);
        this.searchConvert();

    },

    statusval(value){

        this.table_status(value);
        this.searchConvert();

    },

 

   
   crusade_typeEdit(crusade_type_id){

    this.crusade_type_id = crusade_type_id;
    this.view_type = "edit";
    this.getCrusadeTypeDataa(crusade_type_id);
    this.$refs.crusade_type_ref.click();

   },




    crusade_typeView(crusade_type_id)
    {

    this.crusade_type_id = crusade_type_id;
    this.view_type = "view";
    this.getCrusadeTypeDataa(crusade_type_id);
    
    this.$refs.crusade_type_ref.click();

   },




      async getCrusadeTypeDataa(crusade_type_id){
         
             this.showLoading(true);
             try{
            //   console.log("From Watchers",user_portal_id);
            //   console.log("From Props",this.get_user_portal_id);
            
            const response = await axios.get(`crusade_types/${crusade_type_id}`);
            this.showLoading(false);
                this.getCrusadeTypeData = response.data.data;
             }catch(error){
                this.showLoading(false);
             }
          
              },





    crusade_typeAdd(){

        this.view_type = "add";
        this.$refs.crusade_type_add_ref.click();

     },






     crusade_typeAdded(){
        this.getCrusadeType();
     },




           denominationVal(value){

             let field = "Denomination";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if(FieldValidateService.reqVal2(value,field)){
        this.denominationErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.denominationErr);
      }else{
        if(this.errorClient.includes(this.denominationErr))
        {
        let pos = this.errorClient.indexOf(this.denominationErr);
        this.errorClient.splice(pos,1);
        }
        this.denominationErr =""; 
         this.churchByDenomination();
      }

    },







      churchVal(value){

             let field = "Church";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if(this.formData.denomination_id != "ALL" && FieldValidateService.reqVal2(value,field)){
        this.churchErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.churchErr);
      }else{
        if(this.errorClient.includes(this.churchErr))
        {
        let pos = this.errorClient.indexOf(this.churchErr);
        this.errorClient.splice(pos,1);
        }
        this.churchErr =""; 
      }

    },







 async churchByDenomination(){
    try{

        const response = await axios.get(`getChurchByDenomination?denomination_id=${this.formData.denomination_id}`)
        this.getChurches = response.data.data;

       // console.log(this.getChurches);

    }catch(_){

        console.log(_);

    }

    this.getChurches.unshift({"id":"ALL", "church":"ALL"});
},









          search_typeVal(value) {

      let max = 10;
      let field = "Search Type";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.search_typeErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.search_typeErr);
     }else{

           if(this.errorClient.includes(this.search_typeErr))
           {
            let pos = this.errorClient.indexOf(this.search_typeErr);
           this.errorClient.splice(pos,1);
            this.search_typeErr =""; 
           }
          
          

     }
    },






     search_byVal(value){

             let field = "Search By";

                   if(typeof value == "object" && value!=null)
             {
             value = value.search_by;
             }


      if(FieldValidateService.reqVal2(value,field)){
        this.search_byErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.search_byErr);
      }else{
        if(this.errorClient.includes(this.search_byErr))
        {
        let pos = this.errorClient.indexOf(this.search_byErr);
        this.errorClient.splice(pos,1);
        }
        
        this.search_byErr =""; 
      }

    },





         columnVal(value){

             let field = "Column";

              if(typeof value == "object" && value!=null)
             {
                  value.push(value.column);
             }

      if(this.formData.search_type=='DYNAMIC' && FieldValidateService.reqVal2(value,field)){
        this.columnErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.columnErr);
      }else{
        if(this.errorClient.includes(this.columnErr))
        {
        let pos = this.errorClient.indexOf(this.columnErr);
        this.errorClient.splice(pos,1);
        }
        this.columnErr =""; 
      }

    },






          search_valueVal(value) {

      let max = 500;
      let field = "Search Value";
     
     if(this.formData.search_by != 'ALL' && FieldValidateService.reqVal(value,field,max))
     {
       this.search_valueErr = FieldValidateService.reqVal(value,field,max);
        this.errorClient.push(this.search_valueErr);
     }else{

           if(this.errorClient.includes(this.search_valueErr))
           {
            let pos = this.errorClient.indexOf(this.search_valueErr);
           this.errorClient.splice(pos,1);
            this.search_valueErr =""; 
           }
          
          

     }
    },







    view_typeVal(value) {

      let max = 10;
      let field = "Value Type";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.view_typeErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.view_typeErr);
     }else{

           if(this.errorClient.includes(this.view_typeErr))
           {
            let pos = this.errorClient.indexOf(this.view_typeErr);
           this.errorClient.splice(pos,1);
            this.view_typeErr =""; 
           }
          
          

     }
    },






    async searchConvert(){

       this.errorServers = [];
       this.errorClient = [];
       this.showLoading(true);

       
        this.search_typeVal(this.formData.search_type);
        this.search_byVal(this.formData.search_by);
        this.columnVal(this.formData.column);
        this.search_valueVal(this.formData.search_value);
        this.view_typeVal(this.formData.view_type);


          if(this.errorClient.length > 0)
       {
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
          console.log(this.errorClient);
          return true;
       }

       else{

   


                 try{


                   const formData = {

            search_type: this.formData.search_type,
            search_by: this.formData.search_by,
            column: this.formData.column,
            search_value: this.formData.search_value,
            view_type: this.formData.view_type,
            table_search: this.getsearch,
            page_size:  this.perpage,
            sort_direction: this.sort_direction,
            sort_field: this.sort_field
            
        };


            let response = await axios.post("searchPastor",formData);
              if(response.status == 200)
              {
                   this.showLoading(false);

                     //this.clearFormData();
        // notificationBox.succNotifications("Convert Added Successfully");
        // this.$emit("convertAdded");


               if(this.formData.view_type == "GRID")
               {
               
               this.grid_converts = [];
                this.grid_converts = response.data.data.data;
                console.log(response.data.data.data);
              
               }
               else{
                this.report_converts = response.data.data;
               }
              }

                  this.table_per_page(response.data.data.per_page);
             this.table_total(response.data.data.total)


         }catch(error){

                  this.showLoading(false);
                 console.log(error);
      //      this.errorServers.push(error.response.data.error);
           
      //      this.showLoading(false);
           

      //             if(this.errorServers.length > 0)
      //  {
      //     notificationBox.errorNotifications("Some Errors Exist");
      //       this.getServerError();
     
      //  }
      //  else if(error.response.data.data_error){
        
      //      notificationBox.errorNotifications(error.response.data.data_error);
      //  }

         }

       }


    },






  
  },





 async created(){



       
        try{
       let response = await axios.get("getTotalPoastors");
        this.totalPastors =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
       
      
    }catch(_){
        console.log(_);
    }
// this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
// this.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
//console.log(JSON.parse(localStorage.getItem("user_portal_info")))

    //this.currentPage = 1;
    this.table_current_page(1);
    this.getCrusadeType();

    this.getSearchBy = [
        {
                "search_by":"ALL",
                "search_by_act_name":"ALL",
            },
            {
                "search_by":"pastors.fullname",
                "search_by_act_name":"FULLNAME",
            },
        
            {
                "search_by":"pastors.gender",
                "search_by_act_name":"GENDER",
            },
            {
                "search_by":"pastors.contact",
                "search_by_act_name":"CONTACT",
            },
            {
                "search_by":"pastors.email",
                "search_by_act_name":"EMAIL",
            },
            {
                "search_by":"pastors.residential_address",
                "search_by_act_name":"RESIDENTIAL ADDRESS",
            },
            {
                "search_by":"pastors.organisation",
                "search_by_act_name":"ORGANISATION",
            },
            {
                "search_by":"pastors.church_location",
                "search_by_act_name":"CHURCH LOCATION",
            },
            {
                "search_by":"pastors.pastor_name",
                "search_by_act_name":"Pastor's Name",
            },
            {
                "search_by":"pastors.position_in_church",
                "search_by_act_name":"POSITION IN CHURCH",
            },
            {
                "search_by":"pastors.date",
                "search_by_act_name":"Date Registered",
            },

             {
                "search_by":"date>=",
                "search_by_act_name":"DATE>=",
            },
            {
                "search_by":"date<=",
                "search_by_act_name":"DATE<=",
            },
    ];

   // console.log(JSON.parse(JSON.stringify(this.getSearchBy)));

    this.getColumns = [
            {
                "column":"fullname",
                "column_view":"FULLNAME",
                "column_field":"pastors.=.fullname",
            },
            {
                "column":"gender",
                "column_view":"GENDER",
                "column_field":"pastors.age",
            },
            {
                "column":"contact",
                "column_view":"CONTACT",
                "column_field":"pastors.contact",
            },
            {
                "column":"email",
                "column_view":"EMAIL",
                "column_field":"pastors.email",
            },
            {
                "column":"residencial_address",
                "column_view":"RESIDENCIAL ADDRESS",
                "column_field":"pastors.residencial_address",
            },
            {
                "column":"organisation",
                "column_view":"ORGANISATION",
                "column_field":"pastors.organisation",
            },
            {
                "column":"church_location",
                "column_view":"CHURCH LOCATION",
                "column_field":"pastors.church_locatiom",
            },
            {
                "column":"pastors.pastor_name",
                "column_view":"Pastor's Name",
                "column_field":"pastors.pastor_name",
            },
            {
                "column":"pastors.position_in_church",
                "column_view":"POSITION IN CHURCH",
                "column_field":"pastors.position_in_church",
            },

             {
                "column":"pastors.date",
                "column_view":"Date Registered",
                "column_field":"pastors.date",
            }
            
            
            ];
  },


  watch:{


     "selectPage"(value){
         this.checked = [];
       //  this.table_checked([]);

        if(value){
            const crusade_types = JSON.parse(JSON.stringify(this.crusade_types));
            crusade_types.forEach(crusade_type=>{
                this.checked.push(crusade_type.id)
                //this.table_checked_push(user.id);
            });
        
        }else{
              
              this.checked = [];
              //this.table_checked([]);
              //this.selectAll = false;
              this.table_select_all(false);
        }

     },

     "checked"(){

        this.table_url(BASEURL + "crusade_types/export/"+ this.checked);
       // this.url = BASEURL + this.url + this.checked;

     }

  }

};
</script>

<style scoped src="@/assets/css/report.css"></style>