import { createRouter, createWebHistory } from "vue-router";
import LoginView from "@/views/LoginView.vue";
import LoginOtp from "@/views/LoginOtp.vue";
import DashboardView from "@/views/DashboardView.vue";
import DenominationView from "@/views/DenominationView.vue";
import ChurchView from "@/views/ChurchView.vue";
import CrusadeTypeView from "@/views/CrusadeTypeView.vue";
import UserView from "@/views/UserView.vue";
import ConvertView from "@/views/ConvertView.vue";
import SmsSetupView from "@/views/SmsSetupView.vue";
import ConvertReportView from "@/views/ConvertReportView.vue";
import ConverPerChurchView from "@/views/ConverPerChurchView.vue";
import FireConferenceView from "@/views/FireConferenceView.vue";
import FireConferencePerChurchView from "@/views/FireConferencePerChurchView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";



import PastorRegistrationView from "@/views/PastorRegistrationView.vue";
import ResetPasswordView from "@/views/ResetPasswordView.vue";
import RegistrationInfoPage from "@/views/RegistrationInfoPage.vue";
import ActivationView from "@/views/ActivationView.vue";
import ProvidersView from "@/views/ProvidersView.vue";
import SupportMembersView from "@/views/SupportMembersView.vue";
import DependantsView from "@/views/DependantsView.vue";
import SendBulkSmsView from "@/views/SendBulkSmsView.vue";
//import AllUsersView from "@/views/AllUsersView.vue";
import LinkUsersToProvidersView from "@/views/LinkUsersToProvidersView.vue";
import TransactView from "@/views/TransactView.vue";
import MakePaymentView from "@/views/MakePaymentView.vue";
import AllDebtorsView from "@/views/AllDebtorsView.vue";
import MainMenuView from "@/views/MainMenuView.vue";
import NotFound from "@/views/NotFound.vue";
import UserNotAuthorise from "@/views/UserNotAuthorise.vue";

const routes = [{
    path: "/",
    name: "login",
    component: LoginView,
},

    
{
    path: "/main-menu",
    name: "MainMenuView",
    component: MainMenuView,
},
    {
        path: "/denomination",
        name: "Denomination",
        component: DenominationView,
    },
    {
        path: "/church",
        name: "Church",
        component: ChurchView,
    },
    {
        path: "/crusade_type",
        name: "Crusade_Type",
        component: CrusadeTypeView,
    },
    {
        path: "/send_bulk_sms",
        name: "send_bulk_sms",
        component: SendBulkSmsView,
    },
    {
        path: "/pastor_registration",
        name: "pastor_registration",
        component: PastorRegistrationView,
    },
    {
        path: "/user",
        name: "user",
        component: UserView,
    },
    {
        path: "/convert",
        name: "convert",
        component: ConvertView,
    },

    {
        path: "/sms_setup",
        name: "sms_setup",
        component: SmsSetupView,
    },

    {
        path: "/convert_report",
        name: "convert_report",
        component: ConvertReportView,
    },

    {
        path: "/convert_per_church",
        name: "convert_per_church",
        component: ConverPerChurchView,
    },

    {
        path: "/fire_conference_report",
        name: "fire_conference_report",
        component: FireConferenceView,
    },

    {
        path: "/fire_conference_per_church",
        name: "fire_conference_per_church",
        component: FireConferencePerChurchView,
    },

    {
        path: "/forgot-password",
        name: "ForgotPasswordView",
        component: ForgotPasswordView,
    },
    // {
    //     path: "/provider_login",
    //     name: "provider_login",
    //     component: LoginView,
    // },
    // {
    //     path: "/dependent_login",
    //     name: "dependent_login",
    //     component: LoginView,
    // },
    // {
    //     path: "/telemedicine_login",
    //     name: "telemedicine_login",
    //     component: LoginView,
    // },

    {
        path: "/login_otp",
        name: "login_otp",
        component: LoginOtp,
    },
    {
        path: "/dashboard",
        name: "DashboardView",
        component: DashboardView,
    },

  
    {
        path: "/reset/:code",
        name: "ResetPasswordView",
        component: ResetPasswordView,
    },

    {
        path: "/reg-info",
        name: "RegistrationInfoPage",
        component: RegistrationInfoPage,
    },


    

    {
        path: "/activation/:code/:port/:prov_id",
        name: "ActivationView",
        component: ActivationView,
    },

    
   
    {
        path: "/providers",
        name: "ProvidersView",
        component: ProvidersView,
    },

    {
        path: "/transact",
        name: "TransactView",
        component: TransactView,
    },

    {
        path: "/support-members",
        name: "SupportMembersView",
        component: SupportMembersView,
    },
    {
        path: "/dependants",
        name: "DependantsView",
        component: DependantsView,
    },

    {
        path: "/make_payment",
        name: "MakePaymentView",
        component: MakePaymentView,
    },

    // {
    //     path: "/users",
    //     name: "AllUsersView",
    //     component: AllUsersView,
    // },



    {
        path: "/debtors",
        name: "AllDebtorsView",
        component: AllDebtorsView,
    },


    {
        path: "/link-users-to-providers",
        name: "LinkUsersToProvidersView",
        component: LinkUsersToProvidersView,
    },




    {
        path: "/user-authorise-access",
        name: "user-not-authorise",
        component: UserNotAuthorise,
    },
    {path: '/:NotFound(.*)*', name: "pagge-not-found", component: NotFound}
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;