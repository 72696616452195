<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
        <div class="main">
            <div class="section__content section__content--p30">
                <div class="container-fluid">  
                    <HeaderComp page="UserPortal" pageDesc="list of all users and portals"/>                
                    <section id="main-content">
                        <div class="row ps-3 pe-3">
                            <div class="col-md-12">                               
                                <div class="row">

                                            <!-- <div class="col-1 text-end">
                                        <div class="d-flex align-items-center ml-4" style="min-width: 100px; float:right;">
                                            <label for="paginate" class="text-nowrap mr-2 mb-0"><strong>Per Page</strong></label>
                                            <select v-model.number.trim="perPage" class="form-control form-control-sm">
                                                <option value="20">20</option>
                                                <option value="2">2</option>
                                                <option value="40">40</option>
                                                <option value="60">60</option>
                                                <option value="80">80</option>
                                                <option value="100">100</option>
                                                <option value="120">120</option>
                                                <option value="140">140</option>
                                             </select>
                                       
                                        </div>
                                    </div> -->

                                    <PerPageComponent @perpageValue="perpageval" />

                                    <TableFilter @statusValue="statusval" portall="" />



                                    <TableSearch @searchValue="searchval" />


                                  

                                    <ActionButton @deleteRecords="deleteRecords" :num_checked="checked.length" portall="" />





                                
                                    <div class="col-1 text-end">
                                        <!-- <a class="btn btn-outline-success" @click="userAdd()" data-bs-toggle="modal"  href="#userModal">
                                            <i class="fas fa-plus"></i> Add User
                                        </a>    -->


                                                   
                                        <a class="btn btn-outline-success" @click="userportalAdd()" data-bs-toggle="modal"  href="#userPortalModal">
                                            <i class="fas fa-plus"></i> Add User
                                        </a>   



                                  


                                           <a style="display:none" class="btn btn-outline-success" ref="user_portal_ref" data-bs-toggle="modal"  href="#userPortalModal">
                                            <i class="fas fa-plus"></i> User Portal Ref
                                        </a> 



                                           <a style="display:none" class="btn btn-outline-success" ref="link_user_portal_ref" data-bs-toggle="modal"  href="#linkUserPortalModal">
                                            <i class="fas fa-plus"></i> Link User Portal Ref
                                        </a>   





                                        

                              
                                    </div>

                                    

                                     




                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" />


                                </div>
                            </div>
                            
                            <div class="card shadow-sm">
                                <div class="card-body">
                                    <div class="table-responsive table-responsive-data2">
                                        <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                               <th scope="col">View User Portals</th>
                                               
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.fullname')">Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.fullname'">&darr;</span>
                                                </th>


                                                <th scope="col" v-if="app_portal=='MAIN'">
                                                <a href="#" @click.prevent="change_sort('denominations.denomination')">Denomination</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='denominations.denomination'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='denomination.denomination'">&darr;</span>
                                                </th>

                                                <th scope="col" v-if="app_portal=='MAIN' || app_portal=='DENOMINATION'">
                                                <a href="#" @click.prevent="change_sort('churches.church')">Church</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='churches.church'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='churches.church'">&darr;</span>
                                                </th>


                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.gender')">Gender</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.gender'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.gender'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.contact')">Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.contact'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.email')">Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.email'">&darr;</span>
                                                </th>

                                                <!-- <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('user_portals.portal')">Portal</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='user_portals.portal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='user_portals.portal'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('user_portals.user_level')">User Level</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='user_portals.user_level'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='user_portals.user_level'">&darr;</span>
                                                </th> -->

                                     

                                               

                                                <th scope="col">
                                                Action
                                                </th>

                                                </tr>
                                            </thead>
                                            <tbody v-for="user_portal in user_portals" :key="user_portal.id">
                                                <tr :class="isChecked(user_portal.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="user_portal.id"  v-model="checked"/> 
                                                </td>    
                                                <td @click="show_user_portal(user_portal.user_id); user_portal.show_portal = !user_portal.show_portal" style="cursor:pointer">
                                                   <i class="fa fa-eye"></i> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{user_portal.fullname}}</td>
                                                    <td v-if="app_portal=='MAIN'">{{user_portal.denomination}}</td>
                                                    <td v-if="app_portal=='MAIN' || app_portal=='DENOMINATION'">{{user_portal.church}}</td>
                                                    <td>{{user_portal.gender}}</td>
                                                    <td>{{user_portal.contact}}</td>
                                                    <td>{{user_portal.email}}</td>
                                                    <!-- <td>{{user_portal.portal}}</td>
                                                    <td>{{user_portal.user_level }}</td> -->
                                                    <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="user_portalEdit(user_portal.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(user_portal.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="user_portalView(user_portal.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>

                                                                <div class="btn"  title="link User To Portal" @click="linkUserPortal(user_portal.id)">
                                                                    <i class="fa-solid fa-link"></i>
                                                                </div> 
                                                            </li>
                                                        </div>
                                                    </td>
                                                </tr>



                                                   <tr v-if="user_portal.show_portal">
                                                    <td colspan="12">


                                                            <table class="table table-light">

                                                                <thead>
                                                                <tr>
                                                                <th>Portal</th>
                                                                <th>Portal Denomination</th>
                                                                <th>Portal Church</th>		
                                                                <th>User Level</th>	
                                                                <th>Is Active</th>	
                                                                <th>Action</th>	
                                                            </tr>
                                                        </thead>	

                                                        <tbody>
                                                            <tr v-for="portall in get_portals.filter(element => element.user_id == user_portal.user_id)" :key="portall.id" >

                                                                <td>{{portall.portal}}</td>
                                                                <td>{{portall.denomination}}</td>
                                                                <td>{{portall.church}}</td>
                                                                <td>{{portall.user_level}}</td>
                                                                <td>{{portall.is_active}}</td>
                                                                <td>
                                                                    <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="user_portalEdit(portall.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(user_portal.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="user_portalView(portall.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>

                                                                <div class="btn"  title="link User To Portal" @click="linkUserPortal(portall.id)">
                                                                    <i class="fa-solid fa-link"></i>
                                                                </div> 
                                                            </li>
                                                        </div>
                                                                </td>

                                                            </tr>
                                                        </tbody>

                                                            </table>

                                                        

                                                    </td>
                                                </tr>

                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
        </div> 

  

        <UserPortalModals @user_portalAdded="user_portalAdded" :view_type="view_type" :getUserPortalData="getUserPortalData"  />


       <LinkUserPortalModals @user_portalAdded="user_portalAdded"  :getUserPortalData="getUserPortalData"  />
        <!-- <UserModals @userAdded="userAdded"  @provider_view_typee="provider_view_typee"   @country_view_typee="country_view_typee"  :getUserPortalData="getUserPortalData"   :view_type="view_type"  />

        <EmployeeUserCountry @userView="userView" :employee_country_view_type="country_view_type" :employee_user_id="employee_user_id"  />    
  
        <EmployeeUserProvider @userView="userView"   :employee_provider_view_type="provider_view_type" :employee_provider_user_id="employee_provider_user_id" />      -->
        <!-- Modal -->
        <!-- <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> -->
    </div>
</template>


<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"
import PerPageComponent from "@/components/table/PerPage.vue"
import TableSearch from "@/components/table/TableSearch"
import TableFilter from "@/components/table/TableFilter"
import ActionButton from "@/components/table/ActionButton"
import SelectAllComponent from "@/components/table/SelectAllComponent"
import UserPortalModals from "@/components/modalForms/UserPortal.vue"
import LinkUserPortalModals from "@/components/modalForms/LinkUserPortal.vue"
import axios from "axios"

//import "@ocrv/vue-tailwind-pagination/dist/style.css";
import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
import "@ocrv/vue-tailwind-pagination/styles";
import notificationBox from '@/services/notificationBox'
import {BASEURL} from '@/services/UserService';
import {  mapMutations, mapState } from 'vuex'
import { LOADING, TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from '@/store/storeconstants'


export default {
  name: "UserView",
  components: {
    HeaderComp, 
    FooterComp,
    SideBarComp, 
    NavBarComp,
    PerPageComponent,
    TableSearch,
    TableFilter,
    ActionButton,
    SelectAllComponent,
    VueTailwindPagination,
    UserPortalModals,
    LinkUserPortalModals

  },



  data(){
    return {
        user_portals:{},
        get_portals:[],
        get_portals_user_id:[],
        //showUserModals:false,

        //currentPage:1,
        //total:100,
        perPage:20,
        //perPage:20,
        search:"",
        is_active: "",
        checked:[],
        user_table_data:{},
        user_portal_table_data:{},
        getUserPortalData:{},
        view_type:"",
        app_portal:"",
        denomination_id:"",
        church_id:"",
        show_portal:false,
        found:false,
        user_id:[],


        user_portal_id:"",
        login_user_id:"",
        user_level:"",
        selectPage:false,
        //selectAll:false,
     //   sort_direction: 'desc',
     //   sort_field: 'fullname',
      //  url:'',

    }
  },


  computed:{
    ...mapState('dataTable',['currentPage','url','total','perpage','getstatus','getsearch','selectAll','sort_direction','sort_field']),
    ...mapState("user_portal",['search_user_portal_id_result']),
    //...mapState("user",['search_user_id_result'])
  },



  methods:{

       ...mapMutations({
       table_select_all: 'dataTable/'+TABLE_SEL_ALL,
       table_sort_field_direction: 'dataTable/'+TABLE_SORT_FIELD_DIRECTION,
       table_current_page:'dataTable/'+TABLE_CURRENT_PAGE,
       table_url:'dataTable/'+TABLE_URL,
       table_per_page :'dataTable/'+TABLE_PER_PAGE,
       table_total :'dataTable/'+TABLE_TOTAL,
       table_search :'dataTable/'+TABLE_SEARCH,
       table_status :'dataTable/'+TABLE_STATUS,
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),




    selectAllRecords(){

        axios.get('user_portals/all?is_active=' + this.getstatus +
                  '&portal='+ this.app_portal +
                  '&denomination_id='+ this.denomination_id +
                  '&church_id='+ this.church_id
                )
             .then(response => {
                 console.log(response.data);
                  this.checked = response.data;
                  // this.selectAll = true;
                 // this.table_checked(response.data);
                   this.table_select_all(true);
             });
    },



    change_sort(field){

        this.table_sort_field_direction(field);
        this.getUserPortal();

    },



   deleteSingleRecord(id){

    //console.log(id);

    this.showLoading(true);

       axios.delete(`user_portals/${id}`)
       .then( (response) => {
        this.showLoading(false);
        this.checked = this.checked.filter(user_portal_id => user_portal_id != id)
        //this.table_checked(this.checked.filter(user_portal_id => user_portal_id != id))
        console.log(response.data.success)
        notificationBox.succNotifications("Deleted Successfully");
         this.getUserPortal();
       }).catch(error => {
        this.showLoading(false);
        console.log(error.response.data.error)
         notificationBox.errorNotifications(error.response.data.error)
       })


   },



   deleteRecords(){
      axios.delete(`user_portals/massDestroy/${this.checked}`)
      .then((response) => {
         if(response.status == 204){
            console.log(response.data.success)
            notificationBox.succNotifications("Selected User Portal were Deleted Successfully");
            this.checked = [];
            //this.table_checked([])
            this.getUserPortal();

         }
      })
   },





   


    pageChange(pageNumber){

        //this.currentPage = pageNumber;
        this.table_current_page(pageNumber);
        this.getUserPortal();

    },




   isChecked(id){

    return  this.checked.includes(id);

   },



    async getUserPortal(){
          this.showLoading(true);
        
        try{

            const response = await axios.get(
            'user_portals?page=' + this.currentPage + 
            '&page_size=' + this.perpage + 
            '&q=' +this.getsearch + 
            '&is_active=' + this.getstatus +
            '&portal=' + this.app_portal +
            '&denomination_id=' + this.denomination_id +
            '&church_id=' + this.church_id +
            '&sort_direction=' + this.sort_direction +
            '&sort_field=' + this.sort_field
            );

  this.showLoading(false);
            this.user_portals = response.data.data.data;
            //this.perPage = response.data.data.per_page;
               this.table_per_page(response.data.data.per_page);
            //this.total = response.data.data.total;
             this.table_total(response.data.data.total)



        }catch(error){
            this.showLoading(false);
            console.log(error);
        }

   
        // axios.get(
        //     'user_portals?page=' + this.currentPage + 
        //     '&page_size=' + this.perpage + 
        //     '&q=' +this.getsearch + 
        //     '&is_active=' + this.getstatus +
        //     '&portal=' + this.app_portal +
        //     '&denomination_id=' + this.denomination_id +
        //     '&church_id=' + this.church_id +
        //     '&sort_direction=' + this.sort_direction +
        //     '&sort_field=' + this.sort_field
        //     )
        // .then(response => {

        //     this.user_portals = response.data.data.data;
        //     //this.perPage = response.data.data.per_page;
        //        this.table_per_page(response.data.data.per_page);
        //     //this.total = response.data.data.total;
        //      this.table_total(response.data.data.total)
        // });


      //  console.log(this.user_portals);

    },



    perpageval(value){
        this.table_per_page(value);
       this.getUserPortal();

    },



    searchval(value){

         this.table_search(value);
        this.getUserPortal();

    },

    statusval(value){

        this.table_status(value);
        this.getUserPortal();

    },

 
     userportalAdd(){

        this.view_type = "add";

     },


   
   user_portalEdit(user_portal_id){

    this.user_portal_id = user_portal_id;
    this.view_type = "edit";
    this.getUserPortalDataa(user_portal_id);
    this.$refs.user_portal_ref.click();

   },




    
   linkUserPortal(user_portal_id){

    this.user_portal_id = user_portal_id;
    //this.view_type = "link";
    this.getUserPortalDataa(user_portal_id);
    this.$refs.link_user_portal_ref.click();

   },




    user_portalView(user_portal_id){

    this.user_portal_id = user_portal_id;
    this.view_type = "view";
    this.getUserPortalDataa(user_portal_id);
    
    this.$refs.user_portal_ref.click();

   },




      async getUserPortalDataa(user_portal_id){
       
         
             this.showLoading(true);
             try{
            //   console.log("From Watchers",user_portal_id);
            //   console.log("From Props",this.get_user_portal_id);
            
            const response = await axios.get(`user_portals/${user_portal_id}`);
            this.showLoading(false);
                this.getUserPortalData = response.data.data;
         
             }catch(error){
                this.showLoading(false);
             }
          
              },





        async show_user_portal(user_id){
             this.showLoading(true);
                 this.show_portal = !this.show_portal

               //  console.log(user_id)

                 if(this.user_id.includes(user_id)){
                    let pos = this.user_id.indexOf(user_id);
                    this.user_id.splice(pos,1);
                 }else{
                    this.user_id.push(user_id)
                 }

                 


                
          if(this.user_id.length > 0)
          {
        
            try{

               const varData = {
                portal:this.app_portal,
                user_id:this.user_id,
                portal_denomination_id:this.denomination_id,
                portal_church_id:this.church_id,
               }
                  
                const response = await axios.post('getPortal',varData)
                  this.showLoading(false);
                this.get_portals =response.data.data;
                
            //this.check_userId_exist(user_id,this.get_portals);


            }catch(_){
                  this.showLoading(false);
                console.log(_);
            }
          }
          else{
                   this.showLoading(false);
          }
        
        },











     user_portalAdded(){
        this.getUserPortal();
     },






    //  check_userId_exist(user_id, arr)
    //  {
    //     console.log(JSON.stringify(arr));
    //     this.found = arr.some(element => element.user_id == user_id);
    //     console.log(this.found);

    //  }




  
  },





 async created(){



if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
 this.app_portal = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal;
this.denomination_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination_id;
this.church_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church_id;
}
else{

     this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
this.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
this.church_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_church_id;

}
       
       
//this.app_portal = "MAIN";
      
//this.show_portal = !this.show_portal

    //this.currentPage = 1;
    this.table_current_page(1);
    this.getUserPortal();
  },


  watch:{
    // "perPage"(value){
    //    this.table_per_page(value);
    //    this.getUser();
    // },
 
    //  "search"(value){
    //     this.table_search(value);
    //     this.getUser();
    //  },

    //  "status"(value){
    //     this.table_status(value);
    //     this.getUser();
    //  },

     "selectPage"(value){
         this.checked = [];
       //  this.table_checked([]);

        if(value){
            const user_portals = JSON.parse(JSON.stringify(this.user_portals));
            user_portals.forEach(user_portal=>{
                this.checked.push(user_portal.id)
                //this.table_checked_push(user.id);
            });
        
        }else{
              
              this.checked = [];
              //this.table_checked([]);
              //this.selectAll = false;
              this.table_select_all(false);
        }

     },

     "checked"(){

        this.table_url(BASEURL + "user_portals/export/"+ this.checked);
       // this.url = BASEURL + this.url + this.checked;

     }

  }

};
</script>