<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
        <div class="main">
            <div class="section__content section__content--p30">
                <div class="container-fluid">  
                    <HeaderComp page="Sms Setup" pageDesc="list of all sms setups"/>                
                    <section id="main-content">
                        <div class="row ps-3 pe-3">
                            <div class="col-md-12">                               
                                <div class="row">

                                     
<!-- 
                                    <PerPageComponent @perpageValue="perpageval" /> -->

                                    <!-- <TableFilter @statusValue="statusval" portall="" /> -->

                    

                                       <div class="col-4 text-end">
                                        <div style="min-width: 400px; float:right;">
                                                <div class="input-group mb-3">
                                                    <label><b>Sms Short Name</b></label>:
                                                <input type="text" style="margin-left:10px" class="form-control"  v-model="formData.short_name" placeholder="Please Enter Short Name To Setup">
                                                
                                        <button style="margin-left:5px" class="btn btn-success" @click="setupSms()">
                                             Setup
                                        </button>
                                                </div>
 
                                        </div>
                                        </div>


                                  
    


                                    <ActionButton :num_checked="checked.length" portall="" />









                                
                                    <div class="col-2 text-end">
                           


                                                   
                                        <a class="btn btn-outline-success"  data-bs-toggle="modal"  href="#" v-if="formData.sms_setup_id">
                                            SMS Balance: {{ getUnit }}
                                        </a>   


                                        

                              

                                    </div>



                                            <div class="col-2 text-end">
                           


                                                   
                                        <a class="btn btn-outline-primary" href="#">
                                            Cost Per Sms: {{ formData.unit_cost }}
                                        </a>   


                                        

                              

                                    </div>


                            


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" />
                            

                                </div>
                                NB: Please Minimum SMS TO BUY IS 20ghc
                            </div>
                            
                            <div class="card shadow-sm">
                                <div class="card-body">

<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <button class="nav-link active" id="nav-home-tab" ref="show_all" data-bs-toggle="tab" @click="view_tab('home')" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Buy Sms Credit/ Unit</button>
    <button class="nav-link" id="nav-transfer-sms-tab" data-bs-toggle="tab" @click="view_tab('transfer_sms')"   data-bs-target="#nav-transfer-sms" type="button" role="tab" aria-controls="nav-transfer-sms" aria-selected="false" v-if="app_portal!='MAIN'">Transfer SMS</button>
    <!-- <button class="nav-link" id="nav-pending-provider-tab" ref="show_pending" data-bs-toggle="tab"  @click="show_providers('PENDING')" data-bs-target="#nav-pending-provider" type="button" role="tab" aria-controls="nav-active-provider" aria-selected="false">Pending</button>
    <button class="nav-link" id="nav-inactive-provider-tab" ref="show_rejected" data-bs-toggle="tab"  @click="show_providers('REJECTED')"  data-bs-target="#nav-inactive-provider" type="button" role="tab" aria-controls="nav-inactive-provider" aria-selected="false">Rejected</button> -->
  </div>
</nav>

<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane fade show active"  @click="getSmsUnit()" id="nav-home"  role="tabpanel" aria-labelledby="nav-home-tab"  v-if="formData.sms_setup_id">

        <iframe  :src="src" style="border:none; height:500px; width:900px;" /> 
        <button class="btn btn-primary" @click="getSmsUnit()">Sync Balance</button>

  </div>
  <div class="tab-pane fade show active" id="nav-home"  role="tabpanel" aria-labelledby="nav-home-tab"  v-else>

        <h4>Please Setup SMS On Top</h4>

  </div>

    <div class="tab-pane fade" id="nav-transfer-sms" role="tabpanel" aria-labelledby="nav-transfer-sms-tab" v-if="app_portal!='MAIN'">
            
   <form class="register-form" @submit.prevent="reg()">

    <div class="card-body" id="stylized">

   
                 <div class="row" style="margin-bottom:10px">

                      <div class="col">
                                <label><span class="text_error">*</span>To Church</label>
                             <div class="input-group">
                          

                         <v-select :options="getChurches" multiple class="form-control select1" v-model="formData.church_id"  :reduce="church => church.id" label="church" @option:selected="churchVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: churchErr }"
                            v-if="churchErr"
                          >
                            {{ churchErr }}
                          </div>
                        </div>




                      <div class="col">
                        <label>Unit Transfer To Each Church</label>
                        <div class="input-group">
                          

                          <input
                            type="number"
                            class="form-control"
                            :class="{ form_error: unit_transferErr}"
                            placeholder="Unit Transfer To Each Church"
                            @blur="unit_transferVal($event.target.value)" 
                            v-model.trim="formData.unit_transfer"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: unit_transferErr  }"
                           v-if="unit_transferErr">
                          {{ unit_transferErr }} 
                          </div>
                      </div>




                 </div>



                 <div class="row" style="margin-bottom:10px">


                       <div class="col">
                        <label>Total Unit To Transfer</label>
                        <div class="input-group">
                          

                          <input
                            type="number"
                            readonly
                            class="form-control"
                            :class="{ form_error: total_unit_transferErr}"
                            placeholder="Total Unit To Transfer"
                            v-model.trim="formData.total_unit_transfer"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: total_unit_transferErr  }"
                           v-if="total_unit_transferErr">
                          {{ total_unit_transferErr }} 
                          </div>
                      </div>

                 </div>



                <button type="submit" class="btn btn-primary">Transfer</button>

                
    </div>

   </form>


  </div>


</div>


                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
        </div> 

  

        <!-- <ConvertModals @convertAdded="convertAdded" :convert_view_type="view_type" :convert_data="getConvertData"  /> -->

        <!-- <UserModals @userAdded="userAdded"  @provider_view_typee="provider_view_typee"   @country_view_typee="country_view_typee"  :getUserPortalData="getUserPortalData"   :view_type="view_type"  />

        <EmployeeUserCountry @userView="userView" :employee_country_view_type="country_view_type" :employee_user_id="employee_user_id"  />    
  
        <EmployeeUserProvider @userView="userView"   :employee_provider_view_type="provider_view_type" :employee_provider_user_id="employee_provider_user_id" />      -->
        <!-- Modal -->
        <!-- <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> -->
    </div>
</template>


<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"
import ActionButton from "@/components/table/ActionButton"
import SelectAllComponent from "@/components/table/SelectAllComponent"
import axios from "axios"
import FieldValidateService from "@/services/FieldValidateService";
import vSelect from 'vue-select'

//import "@ocrv/vue-tailwind-pagination/dist/style.css";
import "@ocrv/vue-tailwind-pagination/styles";
import notificationBox from '@/services/notificationBox'
import {BASEURL} from '@/services/UserService';
import {  mapMutations, mapState } from 'vuex'
import { LOADING, TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from '@/store/storeconstants'


export default {
  name: "CrusadeTypeView",
    beforeRouteEnter(to,from,next){


     let user_level = "";
     if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
     const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
     user_level = user_data[0].user_level;
     }
     else{
             const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
             user_level = user_data.user_level;
     }
   
    
    if(user_level =='SUPER ADMIN' || user_level =='DENOMINATION ADMIN' || user_level =='CHURCH ADMIN'){
        next();

    }else{
  
        next("/user-not-authorise"); 
    }
 
 
    
  },
  components: {
    HeaderComp, 
    FooterComp,
    SideBarComp, 
    NavBarComp,
    ActionButton,
    SelectAllComponent,
    vSelect


  },



  data(){
    return {
        converts:{},
        //showUserModals:false,

        //currentPage:1,
        //total:100,
        perPage:20,
        //perPage:20,
        search:"",
        is_active: "",
        checked:[],
        user_table_data:{},
        user_portal_table_data:{},
        getConvertData:{},
        view_type:"",
        app_portal:"",
        getChurches:[],
        user_portal_info:{},
        view_tabb:"",
        smsUnitInterval:"",
        getUnit:"",
        src:"",


        convert_id:"",
        login_user_id:"",
        user_level:"",
        crusade_type_id:"",
        not_setup:"",
      errorClient:[],
      errorServers:[],
        selectPage:false,

     formData:{
        sms_setup_id:"",
        short_name:"",
        sms_unit: "",
        unit_cost: 0.06,
        portal:"",
        portal_id:"",
        denomination_id:"",
        church_id:[],
        unit_transfer:"",
        total_unit_transfer:"",
        user_id:""
     },

     churchErr:"",
     unit_transferErr:"",
     total_unit_transferErr:"",


    }
  },


  computed:{
    ...mapState('dataTable',['currentPage','url','total','perpage','getstatus','getsearch','selectAll','sort_direction','sort_field']),
    ...mapState("user_portal",['search_user_portal_id_result']),
    


  },



  methods:{

       ...mapMutations({
       table_select_all: 'dataTable/'+TABLE_SEL_ALL,
       table_sort_field_direction: 'dataTable/'+TABLE_SORT_FIELD_DIRECTION,
       table_current_page:'dataTable/'+TABLE_CURRENT_PAGE,
       table_url:'dataTable/'+TABLE_URL,
       table_per_page :'dataTable/'+TABLE_PER_PAGE,
       table_total :'dataTable/'+TABLE_TOTAL,
       table_search :'dataTable/'+TABLE_SEARCH,
       table_status :'dataTable/'+TABLE_STATUS,
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),




    selectAllRecords(){

        axios.get('converts/all?portal=' + this.app_portal)
             .then(response => {
                 console.log(response.data);
                  this.checked = response.data;
                  // this.selectAll = true;
                 // this.table_checked(response.data);
                   this.table_select_all(true);
             });
    },



    change_sort(field){
        // if(this.sort_field == field){
        //  this.sort_direction =   this.sort_direction == "asc" ? "desc" : "asc"
        // }else{
        //     this.sort_field = field;
        // }
        this.table_sort_field_direction(field);
        this.getConvert();

    },



  

   


    pageChange(pageNumber){

        //this.currentPage = pageNumber;
        this.table_current_page(pageNumber);
        this.getConvert();

    },




   isChecked(id){

    return  this.checked.includes(id);

   },



    getConvert(){
   
        axios.get(
            'converts?page=' + this.currentPage + 
            '&page_size=' + this.perpage + 
            '&q=' +this.getsearch + 
            '&sort_direction=' + this.sort_direction +
            '&sort_field=' + this.sort_field +
            '&portal=' + this.app_portal +
            '&convert_id=' + this.convert_id + 
            '&denomination_id=' + this.denomination_id + 
            '&crusade_type_id=' + this.crusade_type_id + 
            '&church_id=' + this.church_id  
            )
        .then(response => {

            this.converts = response.data.data.data;
            //this.perPage = response.data.data.per_page;
               this.table_per_page(response.data.data.per_page);
            //this.total = response.data.data.total;
             this.table_total(response.data.data.total)
        });

    },



    perpageval(value){
        this.table_per_page(value);
       this.getConvert();

    },


    // banSucc(){

    //     this.getSupportMember();

    // },


    searchval(value){

         this.table_search(value);
        this.getConvert();

    },

    // statusval(value){

    //     this.table_status(value);
    //     this.getCrusadeType();

    // },

 

   
   convertEdit(convert_id){

    this.convert_id = convert_id;
    this.view_type = "edit";
    this.getConvertDataa(convert_id);
    this.$refs.convert_ref.click();

   },




    convertView(convert_id)
    {

    this.convert_id = convert_id;
    this.view_type = "view";
    this.getConvertDataa(convert_id);
    
    this.$refs.convert_ref.click();

   },




      async getConvertDataa(convert_id){
         
             this.showLoading(true);
             try{
            //   console.log("From Watchers",user_portal_id);
            //   console.log("From Props",this.get_user_portal_id);
            
            const response = await axios.get(`converts/${convert_id}`);
            this.showLoading(false);
                this.getConvertData = response.data.data;
             }catch(error){
                this.showLoading(false);
             }
          
              },





    convertAdd(){

        this.view_type = "add";

     },






     convertAdded(){
        this.getConvert();
     },



   async setupSms(){
    this.showLoading(true);
    
             if(!this.formData.short_name)
       {
         this.showLoading(false);
          notificationBox.errorNotifications("Please Enter Short Name");
          console.log(this.errorClient);
          return true;
       }
       else if(this.formData.short_name.length > 11)
       {
              this.showLoading(false);
          notificationBox.errorNotifications("Short Name Should Not Exceed 11 Characters");
          //console.log(this.errorClient);
          return true;
       }
       else{


          if(!this.formData.sms_setup_id)
          {
              try{
               
              const response = await axios.post("sms_setups",this.formData) 

                    if(response.status == 200)
              {
                   this.showLoading(false);

          this.formData.sms_setup_id = response.data.data.id;
        notificationBox.succNotifications("Sms Setup Successfully");
         location.reload();
              }

              }catch(error){
                notificationBox.errorNotifications(error.response.data.error.short_name)
              }

          }


          else {

              try{
                const response = await axios.patch(`sms_setups/${this.formData.sms_setup_id}`,this.formData) 

                    if(response.status == 200)
              {
                   this.showLoading(false);

        notificationBox.succNotifications("Sms Setup Edited Successfully");
               
              }

              }catch(error){

                this.showLoading(false);
               // console.log(error)
                notificationBox.errorNotifications(error.response.data.error.short_name)
              }

          }


       }
     
   },







   // Get Church By Denomination

async churchByDenomination(){
    try{

        const response = await axios.get(`getChurchesByDenominationSms?denomination_id=${this.formData.denomination_id}`)
        this.getChurches = response.data.data;

       // console.log(this.getChurches);

    }catch(_){

        console.log(_);

    }
},







    churchVal(value){

   //  let max = 499;
      let field = " Full name";
     
     if((this.app_portal=="CHURCH" || this.app_portal=="DENOMINATION") && FieldValidateService.reqVal(value,field))
     {
       this.churchErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.churchErr);
     }else{

           if(this.errorClient.includes(this.churchErr))
           {
            let pos = this.errorClient.indexOf(this.churchErr);
           this.errorClient.splice(pos,1);
            this.churchErr =""; 
           }


           
     if(this.formData.unit_transfer)
     {
        this.formData.total_unit_transfer = this.formData.church_id.length * this.formData.unit_transfer;
     }
          
          

     }

   },







   
         unit_transferVal(value){

      let field = "Unit Transfer"

         if(FieldValidateService.reqValNum2(value, field))
     {
       this.unit_transferErr = FieldValidateService.reqValNum2(value,field);
        this.errorClient.push(this.unit_transferErr);
     }else{

           this.unit_transferErr =""; 
          this.errorClient.pop(this.unit_transferErr);

     }


     if(this.formData.church_id.length > 0)
     {
        this.formData.total_unit_transfer = this.formData.church_id.length * this.formData.unit_transfer;
     }


    },








    async reg(){

       this.showLoading(true);

      this.churchVal(this.formData.church_id);
      this.unit_transferVal(this.formData.unit_transfer);

      
             if(this.errorClient.length > 0)
       {
          this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
          console.log(this.errorClient);
          return true;
       }else{

                 try{
            const response = await axios.post("transfer_unit",this.formData);
              if(response.status == 200)
              {
                      this.showLoading(false);

                     this.clearFormData();
        notificationBox.succNotifications("Transfer Done Successfully");
              }
                 }catch(error){

                 console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");

                  if(this.errorServers.length > 0)
       {
          
            this.getServerError();
     
       }

         }

       }

    },




    clearFormData(){
       this.formData.church_id = [];
       this.formData.unit_transfer = "";
       this.formData.total_unit_transfer = "";

       this.churchErr = "";
       this.unit_transferErr = "";
       this.total_unit_transferErr = "";
    },


    getServerError(){


       this.churchErr = 'church_id' in this.errorServers[0] ? this.errorServers[0].church_id[0] : '';
       this.unit_transferErr = 'unit_transfer' in this.errorServers[0] ? this.errorServers[0].unit_transfer[0] : '';
       this.total_unit_transferErr = 'total_unit_transfer' in this.errorServers[0] ? this.errorServers[0].total_unit_transfer[0] : '';
      //  this.pastor_nameErr = 'pastor_name' in this.errorServers[0] ? this.errorServers[0].pastor_name[0] : '';
      // this.is_activeErr = 'is_active' in this.errorServers[0] ? this.errorServers[0].is_active[0] : '';


     
           return true;


    },


    view_tab(tab)
    {

      this.view_tabb = tab;

    },



   async getSmsUnit(){
      this.showLoading(true);
           try {
           let response = await axios.get(`getSmsSetupInfo?portal=${this.app_portal}&portal_id=${this.formData.portal_id}`);
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null}) 
     this.showLoading(false);  
        this.getUnit = response.data.unit

    
      
    }catch(error){
      this.showLoading(false);
       console.log(error);
    }

   }



  
  },







 async created(){



       
if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
this.app_portal = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal;

   this.formData.user_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].user_id;
this.formData.portal = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal;
if(this.app_portal == "DENOMINATION")
{
  this.formData.portal_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination_id;
  this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination_id;
}

else if(this.app_portal == "CHURCH")
{
  this.formData.portal_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church_id;
  this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination_id;
}

else if(this.app_portal == "MAIN")
{
  this.formData.portal_id = "";
}



}
else{

    this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
    this.formData.user_id = JSON.parse(localStorage.getItem("user_portal_info")).user_id;
    this.formData.portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
    if(this.app_portal == "DENOMINATION")
{
  this.formData.portal_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
    this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
}

else if(this.app_portal == "CHURCH")
{
  this.formData.portal_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_church_id;
    this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
}

else if(this.app_portal == "MAIN")
{
  this.formData.portal_id = "";
}

}


 this.showLoading(true);


     try{
       let response = await axios.get(`getSmsSetupInfo?portal=${this.app_portal}&portal_id=${this.formData.portal_id}`);
   this.showLoading(false);
  // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        const sms_setup_info =  response.data.data;
        this.formData.sms_setup_id = sms_setup_info.id;
        this.formData.short_name = sms_setup_info.short_name;
        this.getUnit = response.data.unit;
        this.formData.sms_unit = response.data.unit;
      
    }catch(error){
       this.showLoading(false);
       console.log(error);
    }

     this.src = `https://www.2pesewas.com/payments/index.php?source=2Pesewas_Gateway&&account_id=1255&&payment_id=${this.formData.sms_setup_id}`
     this.churchByDenomination();
    //this.currentPage = 1;
    this.table_current_page(1);
    this.getConvert();
  },


  watch:{



     "selectPage"(value){
         this.checked = [];
       //  this.table_checked([]);

        if(value){
            const converts = JSON.parse(JSON.stringify(this.converts));
            converts.forEach(convert=>{
                this.checked.push(convert.id)
                //this.table_checked_push(user.id);
            });
        
        }else{
              
              this.checked = [];
              //this.table_checked([]);
              //this.selectAll = false;
              this.table_select_all(false);
        }

     },

     "checked"(){

        this.table_url(BASEURL + "converts/export/"+ this.checked);
       // this.url = BASEURL + this.url + this.checked;

     }

  }

};
</script>